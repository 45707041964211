import React from 'react';

export const AbonoContext = React.createContext(null)

export const AbonoProvider = (props) => {

  const { abono, setAbono, formRef, formValidated } = props;

  return (
    <AbonoContext.Provider value={{ abono, setAbono, formRef, formValidated }}>
      {props.children}
    </AbonoContext.Provider>
  )
}

