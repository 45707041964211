import React from 'react'

import Modal from 'react-bootstrap/Modal';
import ExentoService from '../../../../services/exento-service';
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { AiOutlineEdit } from "react-icons/ai";
import { ImCancelCircle } from "react-icons/im";

import { Link } from "react-router-dom";
import { UIContext } from '../../../../contexts/uiContext';
import UtilsService from '../../../../services/utils-service';

function ExentoList() {

  const [exentos, setExentos] = React.useState([])
  const [showModal, setShowModal] = React.useState(false)
  const { showMessage } = React.useContext(UIContext)
  const [exentoSeleccionado, setExentoSeleccionado] = React.useState({});

  React.useEffect(() => {
    ExentoService.getExentos().then(data => { setExentos(data) })
  }, [])

  const anularExento = id => {
    ExentoService.anularExento(id).then(data => {
      setShowModal(false)
      buscarExentos()
    }).catch(error => {
      showMessage(error, 'error');
    })
  }

  function buscarExentos() {
    ExentoService.getExentos().then(data => {
      setExentos(data)
    })
  }

  return (
    <div>
      <Container className="central-div">
        <Row>
          <Col>
            <h1>Personas Exentas</h1>
            <Link to={"/exentos/new"} style={{ float: 'right' }}>
              <Button >
                + Agregar exento
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>DNI</th>
                  <th>Apellido</th>
                  <th>Nombre</th>
                  <th>Localidad</th>
                  <th>Teléfono</th>
                  <th>Dirección</th>
                  <th>Aplica desde</th>
                  <th>Aplica hasta</th>
                  <th>Estado</th>
                  <th>Motivo</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {exentos.map((exento, index) =>
                  <tr key={index}>
                    <td>{exento.dni}</td>
                    <td>{exento.apellido}</td>
                    <td>{exento.nombre}</td>
                    <td>{exento.localidad}</td>
                    <td>{exento.telefono}</td>
                    <td>{exento.direccion}</td>
                    <td>{UtilsService.formatDate(exento.fechaDesde)}</td>
                    <td>{UtilsService.formatDate(exento.fechaHasta)}</td>
                    <td>{(exento.fechaHasta < Date.now() && exento.estado === 'ACTIVO') ? 'VENCIDO' : exento.estado}</td>
                    <td>{exento.motivo}</td>
                    <td>
                      {exento.estado === 'ACTIVO' && <span style={{ cursor: 'pointer', marginRight: '20px' }}  >
                        <ImCancelCircle onClick={() => {
                          setShowModal(true);
                          setExentoSeleccionado(exento);
                        }} />
                      </span>}
                      <span>
                        <Link to={"/exentos/" + exento.id}>
                          <AiOutlineEdit />
                        </Link>
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }} ><h4>¿Desea anular exento?</h4></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={() => {
            anularExento(exentoSeleccionado.id)
          }}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>


    </div>

  )
}

export default ExentoList