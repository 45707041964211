const tiposAbono = [
  { value: 'MENORES_1', label: '4 a 11 años', precioAbono: 'menoresAbonoMensual1', cantidadMaximaFamiliares: 0 },
  { value: 'MENORES_2', label: '12 a 18 años', precioAbono: 'menoresAbonoMensual2', cantidadMaximaFamiliares: 0 },
  { value: 'ADULTOS', label: 'Adultos', precioAbono: 'adultosAbonoMensual', cantidadMaximaFamiliares: 0 },
  { value: 'JUBILADOS', label: 'Jubilados', precioAbono: 'jubiladosAbonoMensual', cantidadMaximaFamiliares: 0 },
  { value: 'GRUPO_FAMILIAR', label: 'Grupo Familiar', precioAbono: 'grupoFamiliarAbonoMensual', cantidadMaximaFamiliares: 3 },
  { value: 'MUNICIPALES_INDIVIDUAL', label: 'Municipales Individual', precioAbono: 'municipalesIndividualAbonoMensual', cantidadMaximaFamiliares: 0 },
  { value: 'MUNICIPALES_GRUPO_FAMILIAR', label: 'Municipales Grupo Familiar', precioAbono: 'municipalesGrupoFamiliarAbonoMensual', cantidadMaximaFamiliares: 5 },
  { value: 'EMPRESAS', label: 'Empresas por 6 personas', precioAbono: 'empresasAbonoMensual', cantidadMaximaFamiliares: 5 },
];



export default tiposAbono;