import React from "react";

import '../../../../App.css'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { Link, useParams, useNavigate } from "react-router-dom";

import DatePicker from "../../../shared/date-picker";

import PreciosService from '../../../../services/precios-service'
import { UIContext } from '../../../../contexts/uiContext'
import { CurrencyInput, formatCurrency, unformatCurrency } from "../../../shared/currency-input";

const emptyForm = {
  fechaFin: new Date()
}


function PreciosNew() {

  const navigate = useNavigate()
  const [form, setForm] = React.useState(emptyForm)
  const [showModal, setShowModal] = React.useState(false)
  const { showMessage } = React.useContext(UIContext)
  const { id } = useParams()

  const [menoresLocales1, setMenoresLocales1] = React.useState(0);
  const [menoresLocales2, setMenoresLocales2] = React.useState(0);
  const [adultosLocales, setAdultosLocales] = React.useState(0);
  const [jubiladosLocales, setJubiladosLocales] = React.useState(0);
  const [grupoFamiliarLocales, setGrupoFamiliarLocales] = React.useState(0);
  const [carpasLocales, setCarpasLocales] = React.useState(0);
  const [menoresGenerales1, setMenoresGenerales1] = React.useState(0);
  const [menoresGenerales2, setMenoresGenerales2] = React.useState(0);
  const [adultosGenerales, setAdultosGenerales] = React.useState(0);
  const [jubiladosGenerales, setJubiladosGenerales] = React.useState(0);
  const [grupoFamiliarGenerales, setGrupoFamiliarGenerales] = React.useState(0);
  const [carpasGenerales, setCarpasGenerales] = React.useState(0);
  const [menoresAbonoMensual1, setMenoresAbonoMensual1] = React.useState(0);
  const [menoresAbonoMensual2, setMenoresAbonoMensual2] = React.useState(0);
  const [adultosAbonoMensual, setAdultosAbonoMensual] = React.useState(0);
  const [jubiladosAbonoMensual, setJubiladosAbonoMensual] = React.useState(0);
  const [grupoFamiliarAbonoMensual, setGrupoFamiliarAbonoMensual] = React.useState(0);
  const [municipalesIndividualAbonoMensual, setMunicipalesIndividualAbonoMensual] = React.useState(0);
  const [municipalesGrupoFamiliarAbonoMensual, setMunicipalesGrupoFamiliarAbonoMensual] = React.useState(0);
  const [empresasAbonoMensual, setEmpresasAbonoMensual] = React.useState(0);


  React.useEffect(() => {
    if (id) {
      PreciosService.getPrecio(id).then(precio => {
        setForm(precio)
        setMenoresLocales1(formatCurrency(precio.menoresLocales1));
        setMenoresLocales2(formatCurrency(precio.menoresLocales2));
        setAdultosLocales(formatCurrency(precio.adultosLocales));
        setJubiladosLocales(formatCurrency(precio.jubiladosLocales));
        setGrupoFamiliarLocales(formatCurrency(precio.grupoFamiliarLocales));
        setCarpasLocales(formatCurrency(precio.carpasLocales));
        setMenoresGenerales1(formatCurrency(precio.menoresGenerales1));
        setMenoresGenerales2(formatCurrency(precio.menoresGenerales2));
        setAdultosGenerales(formatCurrency(precio.adultosGenerales));
        setJubiladosGenerales(formatCurrency(precio.jubiladosGenerales));
        setGrupoFamiliarGenerales(formatCurrency(precio.grupoFamiliarGenerales));
        setCarpasGenerales(formatCurrency(precio.carpasGenerales));
        setMenoresAbonoMensual1(formatCurrency(precio.menoresAbonoMensual1));
        setMenoresAbonoMensual2(formatCurrency(precio.menoresAbonoMensual2));
        setAdultosAbonoMensual(formatCurrency(precio.adultosAbonoMensual));
        setJubiladosAbonoMensual(formatCurrency(precio.jubiladosAbonoMensual));
        setGrupoFamiliarAbonoMensual(formatCurrency(precio.grupoFamiliarAbonoMensual));
        setMunicipalesIndividualAbonoMensual(formatCurrency(precio.municipalesIndividualAbonoMensual));
        setMunicipalesGrupoFamiliarAbonoMensual(formatCurrency(precio.municipalesGrupoFamiliarAbonoMensual));
        setEmpresasAbonoMensual(formatCurrency(precio.empresasAbonoMensual));

      });
    }
  }, [])

  const handleFormValue = (name, value) => {
    const newForm = {
      ...form,
      [name]: value
    }
    setForm(newForm)
  }

  const handleSuccess = message => {
    setShowModal(false)
    showMessage(message, 'success')
    navigate('/precios')
  }

  const submit = () => {
    const body = Object.assign({}, form)

    body.menoresLocales1 = unformatCurrency(menoresLocales1);
    body.menoresLocales2 = unformatCurrency(menoresLocales2);
    body.adultosLocales = unformatCurrency(adultosLocales);
    body.jubiladosLocales = unformatCurrency(jubiladosLocales);
    body.grupoFamiliarLocales = unformatCurrency(grupoFamiliarLocales);
    body.carpasLocales = unformatCurrency(carpasLocales);

    body.menoresGenerales1 = unformatCurrency(menoresGenerales1);
    body.menoresGenerales2 = unformatCurrency(menoresGenerales2);
    body.adultosGenerales = unformatCurrency(adultosGenerales);
    body.jubiladosGenerales = unformatCurrency(jubiladosGenerales);
    body.grupoFamiliarGenerales = unformatCurrency(grupoFamiliarGenerales);
    body.carpasGenerales = unformatCurrency(carpasGenerales);
    
    body.menoresAbonoMensual1 = unformatCurrency(menoresAbonoMensual1);
    body.menoresAbonoMensual2 = unformatCurrency(menoresAbonoMensual2);
    body.adultosAbonoMensual = unformatCurrency(adultosAbonoMensual);
    body.jubiladosAbonoMensual = unformatCurrency(jubiladosAbonoMensual);
    body.grupoFamiliarAbonoMensual = unformatCurrency(grupoFamiliarAbonoMensual);
    body.municipalesIndividualAbonoMensual = unformatCurrency(municipalesIndividualAbonoMensual);
    body.municipalesGrupoFamiliarAbonoMensual = unformatCurrency(municipalesGrupoFamiliarAbonoMensual);
    body.empresasAbonoMensual = unformatCurrency(empresasAbonoMensual);

    if (id) {
      PreciosService.updatePrecio(body).then(precio => {
        handleSuccess('Precios actualizados')
      })
    } else {
      PreciosService.createPrecio(body).then((data) => {
        handleSuccess('Nuevo precio creado')
      })
    }

  }


  return (
    <div>
      <Container className="central-div">
        <Card>
          <h1>Agregar o modificar precios</h1>
          <Card.Body>
            <Form className="central-div-precios" autoComplete="off" style={{ minHeight: '615px' }}>
              <Row>
                <Col>
                  <h3>Precios vigentes hasta la fecha:</h3>
                  <DatePicker
                    onChangeRaw={e => e.preventDefault()}
                    selected={form.fechaFin}
                    onChange={date => handleFormValue('fechaFin', date)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <h1>Locales</h1>



                  <Form.Group controlId="MenoresLocales">
                    <Form.Label>4 a 11 años</Form.Label>
                    <CurrencyInput valueState={{ state: menoresLocales1, stateSetter: setMenoresLocales1 }} />
                  </Form.Group>
                  <Form.Group controlId="MenoresLocales">
                    <Form.Label>12 a 18 años</Form.Label>
                    <CurrencyInput valueState={{ state: menoresLocales2, stateSetter: setMenoresLocales2 }} />
                  </Form.Group>
                  <Form.Group controlId="AdultosLocales">
                    <Form.Label>Adultos</Form.Label>
                    <CurrencyInput valueState={{ state: adultosLocales, stateSetter: setAdultosLocales }} />
                  </Form.Group>
                  <Form.Group controlId="JubiladosLocales">
                    <Form.Label>Jubilados</Form.Label>
                    <CurrencyInput valueState={{ state: jubiladosLocales, stateSetter: setJubiladosLocales }} />
                  </Form.Group>
                  <Form.Group controlId="GrupoFamiliarLocales">
                    <Form.Label>Grupo Familiar</Form.Label>
                    <CurrencyInput valueState={{ state: grupoFamiliarLocales, stateSetter: setGrupoFamiliarLocales }} />
                  </Form.Group>
                  <Form.Group controlId="CarpasLocales">
                    <Form.Label>Carpas</Form.Label>
                    <CurrencyInput valueState={{ state: carpasLocales, stateSetter: setCarpasLocales }} />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <h1>Generales</h1>
                  <Form.Group controlId="MenoresGenerales">
                    <Form.Label>4 a 11 años</Form.Label>
                    <CurrencyInput valueState={{ state: menoresGenerales1, stateSetter: setMenoresGenerales1 }} />
                  </Form.Group>
                  <Form.Group controlId="MenoresGenerales">
                    <Form.Label>12 a 18 años</Form.Label>
                    <CurrencyInput valueState={{ state: menoresGenerales2, stateSetter: setMenoresGenerales2 }} />
                  </Form.Group>
                  <Form.Group controlId="AdultosGenerales">
                    <Form.Label>Adultos</Form.Label>
                    <CurrencyInput valueState={{ state: adultosGenerales, stateSetter: setAdultosGenerales }} />
                  </Form.Group>
                  <Form.Group controlId="JubiladosGenerales">
                    <Form.Label>Jubilados</Form.Label>
                    <CurrencyInput valueState={{ state: jubiladosGenerales, stateSetter: setJubiladosGenerales }} />
                  </Form.Group>
                  <Form.Group controlId="GrupoFamiliarGenerales">
                    <Form.Label>Grupo Familiar</Form.Label>
                    <CurrencyInput valueState={{ state: grupoFamiliarGenerales, stateSetter: setGrupoFamiliarGenerales }} />
                  </Form.Group>
                  <Form.Group controlId="CarpasGenerales">
                    <Form.Label>Carpas</Form.Label>
                    <CurrencyInput valueState={{ state: carpasGenerales, stateSetter: setCarpasGenerales }} />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <h1 controlId="AbonoMensual">Abono Mensual</h1>
                  <Form.Group controlId="MENORES_1">
                    <Form.Label>4 a 11 años</Form.Label>
                    <CurrencyInput valueState={{ state: menoresAbonoMensual1, stateSetter: setMenoresAbonoMensual1 }} />
                  </Form.Group>
                  <Form.Group controlId="MENORES_2">
                    <Form.Label>12 a 18 años</Form.Label>
                    <CurrencyInput valueState={{ state: menoresAbonoMensual2, stateSetter: setMenoresAbonoMensual2 }} />
                  </Form.Group>
                  <Form.Group controlId="ADULTOS">
                    <Form.Label>Adultos</Form.Label>
                    <CurrencyInput valueState={{ state: adultosAbonoMensual, stateSetter: setAdultosAbonoMensual }} />
                  </Form.Group>
                  <Form.Group controlId="JUBILADOS">
                    <Form.Label>Jubilados</Form.Label>
                    <CurrencyInput valueState={{ state: jubiladosAbonoMensual, stateSetter: setJubiladosAbonoMensual }} />
                  </Form.Group>
                  <Form.Group controlId="GRUPO_FAMILIAR">
                    <Form.Label>Grupo Familiar</Form.Label>
                    <CurrencyInput valueState={{ state: grupoFamiliarAbonoMensual, stateSetter: setGrupoFamiliarAbonoMensual }} />
                  </Form.Group>
                  <Form.Group controlId="MUNICIPALES_INDIVIDUAL">
                    <Form.Label>Municipales Individual</Form.Label>
                    <CurrencyInput valueState={{ state: municipalesIndividualAbonoMensual, stateSetter: setMunicipalesIndividualAbonoMensual }} />
                  </Form.Group>
                  <Form.Group controlId="MUNICIPALES_GRUPO_FAMILIAR">
                    <Form.Label>Municipales Grupo Familiar</Form.Label>
                    <CurrencyInput valueState={{ state: municipalesGrupoFamiliarAbonoMensual, stateSetter: setMunicipalesGrupoFamiliarAbonoMensual }} />
                  </Form.Group>
                  <Form.Group controlId="EMPRESAS">
                    <Form.Label>Empresas por 6 personas</Form.Label>
                    <CurrencyInput valueState={{ state: empresasAbonoMensual, stateSetter: setEmpresasAbonoMensual }} />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Row className="row-mb-5">
              <Col>
                <div className="central-div">
                  <Link to={"/precios"}>
                    <Button size="lg" variant="danger" className="button-mr-5">
                      Cancelar
                    </Button>
                  </Link>
                  <Button size="lg" type="submit" onClick={() => setShowModal(true)} >
                    Confirmar
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Seguro que quieres actualizar los precios?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={submit}>
            Guardar precios
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}
export default PreciosNew
