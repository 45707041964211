import React from "react";
import DatePicker from "../../shared/date-picker";
import { Table } from 'react-bootstrap';
import { BsPlusLg, BsTrash } from 'react-icons/bs';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import LocalidadSelect from '../../shared/localidad-select';
import PersonaService from '../../../services/persona-service'
import { UIContext } from '../../../contexts/uiContext'
import { AbonoContext } from "../../../contexts/abonoContext";
import tiposAbono from "./tipos-abono";


const emptyAbono = () => ({
  fechaDesde: new Date(),
  fechaHasta: new Date(),
  tipoAbono: null,
  duracionMeses: 1,
  personas: [{
    encargado: true,
    localidad: 'Seguí (Entre Ríos)'
  }],
})

function GenerarAbono() {
  const dniInputRef = React.useRef(null);
  const [lastDniSearched, setLastDniSearched] = React.useState(0)
  const { setLoading } = React.useContext(UIContext)
  const { abono, setAbono, formRef, formValidated } = React.useContext(AbonoContext);

  React.useEffect(() => {
    if (abono == null) {
      setAbono(emptyAbono());
      setLastDniSearched(0)
    }
    if (dniInputRef?.current && !dniInputRef.current.value) {
      dniInputRef.current.focus();
    }
  }, [abono]);


  React.useEffect(() => {
    const { fechaDesde, duracionMeses } = abono || {};
    if (fechaDesde && duracionMeses) {
      const fechaHasta = new Date(fechaDesde);
      fechaHasta.setMonth(fechaDesde.getMonth() + duracionMeses);
      handleAbonoChange("fechaHasta", fechaHasta);
    }
  }, [abono?.fechaDesde, abono?.duracionMeses]);

  function buscarPersonaByDni() {
    const encargado = abono.personas.find(persona => persona.encargado);
    if (encargado.dni &&
      encargado.dni.length >= 7 &&
      encargado.dni !== lastDniSearched) {
      setLoading(true)
      PersonaService.getPersonaByDNI(encargado.dni).then(respuesta => {
        if (respuesta.persona) {

          setAbono(currentAbono => {
            currentAbono.personas[0] = {
              ...currentAbono.personas[0],
              nombre: respuesta.persona.nombre,
              apellido: respuesta.persona.apellido,
              localidad: respuesta.persona.localidad,
              direccion: respuesta.persona.direccion,
              telefono: respuesta.persona.telefono,
            }
            return currentAbono;
          })
        }
      }).catch(error => {
        if (error.response?.status === 404) {
          //Ignoramos este error
        } else {
          console.error(error)
        }
      }).finally(() => setLoading(false))
      setLastDniSearched(encargado.dni)
    }
  }

  function checkKeyPress(event) {
    if (event.key === 'Enter') {
      buscarPersonaByDni();
    }
  }

  const handleAbonoChange = (name, value) => {
    if (name === 'duracionMeses') {
      value = Number(value);
    }
    setAbono((currentAbono) => ({
      ...currentAbono,
      [name]: value
    }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleAbonoChange(name, value);
  }

  const handleTitularChangeEvent = (e) => {
    const { name, value } = e.target;
    handleTitularChange(name, value);
  }

  const handleTitularChange = (name, value) => {
    setAbono((currentAbono) => {
      const newAbono = { ...currentAbono }
      const titular = newAbono.personas.find(persona => persona.encargado);
      titular[name] = value;
      return newAbono;
    })
  }

  function addFamiliar() {
    const tipoAbonoOption = tiposAbono.find((opcion) => opcion.value === abono.tipoAbono);

    //Restamos al encargado
    if (tipoAbonoOption && (abono.personas.length - 1) >= tipoAbonoOption.cantidadMaximaFamiliares) {
      return;
    }

    setAbono(currentAbono => {
      const newAbono = { ...currentAbono }
      newAbono.personas = [
        ...newAbono.personas,
        { nombre: '', apellido: '', dni: '' }
      ]
      return newAbono;
    })
  }

  function removeFamiliar(index) {
    //Sumamos 1 para saltear al encargado
    index = index + 1;
    setAbono(currentAbono => {
      const newAbono = { ...currentAbono }
      newAbono.personas = newAbono.personas.filter((_, i) => i !== index);
      return newAbono;
    })
  }

  function handleFamiliarChangeEvent(index, e) {
    e.preventDefault();
    const { name, value } = e.target;
    setAbono((currentAbono) => {
      const newAbono = { ...currentAbono }
      //Sumamos 1 para saltear el encargado
      const persona = newAbono.personas[index + 1]
      persona[name] = value;
      return newAbono;
    })
  }

  return (
    <Form
      ref={formRef}
      noValidate
      validated={formValidated}
      autoComplete="off"
      style={{ textAlign: 'center', width: '100%', alignItems: 'center' }}>
      {abono == null ? <></>
        :
        <>
          <Row >
            <Col md={4} >
              <Card.Title></Card.Title>
              <Form.Group >
                <Form.Label htmlFor="dni">DNI</Form.Label>
                <Form.Control type="text" name="dni"
                  required
                  ref={dniInputRef}
                  value={abono.personas.find(persona => persona.encargado).dni}
                  onChange={handleTitularChangeEvent}
                  onKeyDown={checkKeyPress}
                  onBlur={buscarPersonaByDni} />
              </Form.Group>

              <Form.Group >
                <Form.Label htmlFor="nombre">Nombre</Form.Label>
                <Form.Control type="text" name="nombre"
                  required
                  value={abono.personas.find(persona => persona.encargado).nombre}
                  onChange={handleTitularChangeEvent} />
              </Form.Group>

              <Form.Group >
                <Form.Label htmlFor="apellido">Apellido</Form.Label>
                <Form.Control type="text" name="apellido"
                  required
                  value={abono.personas.find(persona => persona.encargado).apellido}
                  onChange={handleTitularChangeEvent} />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group >
                <Form.Label htmlFor="localidad">Localidad</Form.Label>
                <LocalidadSelect value={abono.personas.find(persona => persona.encargado).localidad}
                  name="localidad"
                  onSelect={handleTitularChangeEvent} />
              </Form.Group>

              <Form.Group >
                <Form.Label htmlFor="direccion">Dirección</Form.Label>
                <Form.Control type="text" name="direccion"
                  required
                  value={abono.personas.find(persona => persona.encargado).direccion}
                  onChange={handleTitularChangeEvent} />
              </Form.Group>

              <Form.Group >
                <Form.Label htmlFor="telefono">Teléfono</Form.Label>
                <Form.Control type="text" name="telefono"
                  required
                  value={abono.personas.find(persona => persona.encargado).telefono}
                  onChange={handleTitularChangeEvent} />
              </Form.Group>
            </Col>

            <Col md={4} >
              <Form.Group className="tipo-abono">
                <Form.Label htmlFor="tipo-abono">Tipo de abono</Form.Label>
                <Form.Select value={abono.tipoAbono} name="tipoAbono"
                  required
                  onChange={handleChange}>
                  <option value="">Seleccione un tipo de abono</option>
                  {tiposAbono.map(option =>
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  )}
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Label>Tiempo de abono:</Form.Label>
                <div>
                  <Form.Check type="radio"
                    id="radio1mes"
                    label="1 mes"
                    inline
                    name="duracionMeses"
                    value={1}
                    checked={abono.duracionMeses === 1}
                    onChange={handleChange}
                  />
                  <Form.Check type="radio"
                    id="radio2mes"
                    label="2 meses"
                    inline
                    name="duracionMeses"
                    value={2}
                    checked={abono.duracionMeses === 2}
                    onChange={handleChange}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>Abono vigente desde: </Form.Label>
                <Form.Floating>
                  <DatePicker
                    className="date-picker-input"
                    onChangeRaw={e => e.preventDefault()}
                    selected={abono.fechaDesde}
                    onChange={date => {
                      handleAbonoChange('fechaDesde', date);
                    }}
                  />
                </Form.Floating>
              </Form.Group>
              <Form.Group>
                <Form.Label>Abono vigente hasta: </Form.Label>
                <Form.Floating>
                  <DatePicker disabled='disabled'
                    selected={abono.fechaHasta}
                    onChange={date => {
                      handleAbonoChange('fechaHasta', date);
                    }}
                  />
                </Form.Floating>
              </Form.Group>
            </Col>

          </Row>
          <br></br>
          {abono?.tipoAbono && tiposAbono.find((option) => option.value === abono.tipoAbono).cantidadMaximaFamiliares > 0 ? (
            <>
              <h1 className="abono-titulo">Datos de los familiares</h1>
              <Row>
                <Col>
                  <Form.Group className="tabla-abono">
                    <Table bordered size="sm">
                      <thead>
                        <tr>
                          <th>DNI</th>
                          <th>Nombre</th>
                          <th>Apellido</th>
                          <th>
                            <Button onClick={addFamiliar}
                              id="ButtonAddFamily"
                              size="sm"
                              title="Agregar familiar">
                              <BsPlusLg />
                            </Button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {abono.personas.filter(persona => !persona.encargado).map((persona, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <Form.Control type="text" name="dni"
                                  required
                                  placeholder="DNI"
                                  value={persona.dni}
                                  onChange={e => handleFamiliarChangeEvent(index, e)} />
                              </td>
                              <td>
                                <Form.Control type="text" name="nombre"
                                  required
                                  placeholder="Nombre"
                                  value={persona.nombre}
                                  onChange={e => handleFamiliarChangeEvent(index, e)} />
                              </td>
                              <td>
                                <Form.Control type="text" name="apellido"
                                  required
                                  placeholder="Apellido"
                                  value={persona.apellido}
                                  onChange={e => handleFamiliarChangeEvent(index, e)} />
                              </td>
                              <td>
                                <Button
                                  onClick={() => removeFamiliar(index)}
                                  size="sm"
                                  title="Quitar Familiar"
                                  variant="danger"
                                >
                                  <BsTrash />
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Form.Group>
                </Col>
              </Row></>) : <></>}
        </>
      }
    </Form>
  )
}
export default GenerarAbono;