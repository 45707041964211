import React, { useState } from "react";

import '../../../../App.css'
import { useParams, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import { UIContext } from '../../../../contexts/uiContext'
import { Modal } from "react-bootstrap";
import AbonoService from "../../../../services/abono-service";
import GenerarAbono from "../formAbono";
import { AbonoProvider } from "../../../../contexts/abonoContext";

function AbonosNew() {

  const [showModal, setShowModal] = React.useState(false)
  const { showMessage } = React.useContext(UIContext)
  const navigate = useNavigate()
  const [abono, setAbono] = React.useState(null)
  const { id } = useParams()
  const [loading, setLoading] = React.useState(true);
  const abonoFormRef = React.useRef(null);
  const [abonoFormValidated, setAbonoFormValidated] = React.useState(false);

  React.useEffect(() => {
    if (id) {
      AbonoService.getAbono(id).then(abono => {
        setAbono(abono);
        setLoading(false);
      });
    }
  }, []);


  const handleSuccess = message => {
    setShowModal(false)
    showMessage(message, 'success')
    navigate('/abono')
  }

  function clear() {
    setAbono(null);
    setAbonoFormValidated(false);
  }

  const submit = () => {
    setAbonoFormValidated(true);
    if (!abonoFormRef.current.checkValidity()) {
      return;
    }
    if (abono.id) {
      AbonoService.updateAbono(abono).then((data) => {
        handleSuccess('Abono actualizado');
      });
    } else {
      AbonoService.createAbono(abono).then((data) => {
        handleSuccess('Nuevo abono creado');
      });
    }

    setShowModal(false);
    setLoading(false);
  }


  return (
    <div>
      <Container className="central-div">
        <Modal.Title>Datos del Titular</Modal.Title><br></br>

        <AbonoProvider abono={abono} setAbono={setAbono} formRef={abonoFormRef} formValidated={abonoFormValidated}>
          <GenerarAbono />
        </AbonoProvider>

        <Row className="row-mb-5">
          <Col>
            <div className="central-div">
              <Button size="lg" variant="danger" className="button-mr-5" onClick={() => navigate("/abono")}>
                Cancelar
              </Button>
              <Button size="lg" variant="danger" className="button-mr-5" onClick={clear}>
                Limpiar
              </Button>
              <Button size="lg" type="button" onClick={submit}>
                Confirmar
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="row-mb-5">
          <Col>
            <Modal show={showModal} onHide={() => setShowModal(false)} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Confirmación</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: 'center' }} ><h4>¿Generar abono?</h4></Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Cerrar
                </Button>
                <Button variant="primary" onClick={submit}>
                  Confirmar
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>

      </Container>

    </div>
  )
}
export default AbonosNew