import React from "react";

import '../../../../App.css'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import NumericInput from 'react-numeric-input';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import LocalidadSelect from '../../../shared/localidad-select';
import PersonaService from '../../../../services/persona-service'
import EntradasService from '../../../../services/entradas-service'
import PreciosService from '../../../../services/precios-service'
import { UIContext } from '../../../../contexts/uiContext'
import GenerarAbono from "../../abono/formAbono";
import { AbonoProvider } from "../../../../contexts/abonoContext";
import UtilsService from "../../../../services/utils-service";
import tiposAbono from "../../abono/tipos-abono";
import { formatCurrency, unformatCurrency } from "../../../shared/currency-input";

const emptyForm = () => ({
  dni: '',
  nombre: '',
  apellido: '',
  localidad: 'Seguí (Entre Ríos)',
  direccion: '',
  telefono: '',
  menoresLocales1: 0,
  menoresLocales2: 0,
  adultosLocales: 0,
  jubiladosLocales: 0,
  grupoFamiliarLocales: 0,
  carpasLocales: 0,

  menoresGenerales1: 0,
  menoresGenerales2: 0,
  adultosGenerales: 0,
  jubiladosGenerales: 0,
  grupoFamiliarGenerales: 0,
  carpasGenerales: 0,
  precioAbono: 0,
  medioPago: 'EFECTIVO'
})

const radios = [
  { name: 'Efectivo', value: 'EFECTIVO', clickeable: true },
  { name: 'Tarjeta', value: 'TARJETA', clickeable: true },
  { name: 'Abono', value: 'ABONO', clickeable: false },
  { name: 'Exento', value: 'EXENTO', clickeable: false },
];

function Entradas() {
  const [form, setForm] = React.useState(emptyForm())
  const [amount, setAmount] = React.useState(0)
  const [lastDniSearched, setLastDniSearched] = React.useState(0)
  const [abonoVigente, setAbonoVigente] = React.useState({})
  const [exento, setExento] = React.useState({})
  const [validated, setValidated] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false)
  const { showMessage, printDataUrl, setLoading } = React.useContext(UIContext)
  const dniInputRef = React.useRef(null);
  const formRef = React.useRef(null);
  const [nuevoAbono, setNuevoAbono] = React.useState(null)
  const divRef = React.useRef(null);
  const [tipoVenta, setTipoVenta] = React.useState("1");
  const [precios, setPrecios] = React.useState({});
  const abonoFormRef = React.useRef(null);
  const [abonoFormValidated, setAbonoFormValidated] = React.useState(false);

  React.useEffect(() => {
    const focus = () => {
      requestFocus();
    };

    window.addEventListener("focus", focus);

    return () => {
      window.removeEventListener("focus", focus);
    };
  }, []);

  React.useEffect(() => {
    if (tipoVenta === "1") {
      clearFormAbonos();
      requestFocus();
    } else if (tipoVenta === "2") {
      clearFormEntradas();
    }
  }, [tipoVenta])

  React.useEffect(() => {
    if (abonoVigente.id) {
      handleFormValue('medioPago', 'ABONO')
    }
  }, [abonoVigente.id]);

  React.useEffect(() => {
    if (exento.id) {
      handleFormValue('medioPago', 'EXENTO')
    }
  }, [exento.id])

  React.useEffect(() => {
    PreciosService.getPreciosVigente()
      .then(data => setPrecios(data))
      .catch(() => showMessage('Ocurrió un error al recuperar los precios vigentes', 'error'))
  }, []);

  React.useEffect(() => {
    let costoAbono = 0;
    const { tipoAbono, duracionMeses } = nuevoAbono || {};
    if (tipoAbono && duracionMeses) {
      const tipoAbonoOption = tiposAbono.find(option => option.value === nuevoAbono.tipoAbono);
      costoAbono = precios[tipoAbonoOption.precioAbono] * duracionMeses;

      setAmount(costoAbono);
    }

  }, [nuevoAbono?.tipoAbono, nuevoAbono?.duracionMeses])

  React.useEffect(() => {
    const amount =
      form.menoresLocales1 * precios.menoresLocales1 +
      form.menoresLocales2 * precios.menoresLocales2 +
      form.adultosLocales * precios.adultosLocales +
      form.jubiladosLocales * precios.jubiladosLocales +
      form.grupoFamiliarLocales * precios.grupoFamiliarLocales +
      form.carpasLocales * precios.carpasLocales +
      form.menoresGenerales1 * precios.menoresGenerales1 +
      form.menoresGenerales2 * precios.menoresGenerales2 +
      form.adultosGenerales * precios.adultosGenerales +
      form.jubiladosGenerales * precios.jubiladosGenerales +
      form.grupoFamiliarGenerales * precios.grupoFamiliarGenerales +
      form.carpasGenerales * precios.carpasGenerales;

    setAmount(amount);

  }, [precios, form.menoresLocales1, form.menoresLocales2, form.adultosLocales, form.jubiladosLocales, form.grupoFamiliarLocales, form.carpasLocales, form.menoresGenerales1, form.menoresGenerales2, form.adultosGenerales, form.jubiladosGenerales, form.grupoFamiliarGenerales, form.carpasGenerales])

  const imprimirEntrada = entrada => {
    EntradasService.getReporteEntrada(entrada.id).then(dataUrl => {
      printDataUrl(dataUrl);
    }).catch(error => {
      showMessage(error, 'error');
    })
  }

  const requestFocus = () => {
    if (dniInputRef.current) {
      dniInputRef.current.focus();
    }
  }

  const handleFormValue = (name, value) => {
    if (name === 'medioPago') {

      if (value !== 'ABONO') {
        setAbonoVigente({})
      }

      if (value !== 'EXENTO') {
        setExento({})
      }
    }

    const newForm = {
      ...form,
      [name]: value
    }
    setForm(newForm)
  }

  const submit = () => {

    const body = Object.assign({}, form)
    body.montoTotal = amount
    body.idPrecio = precios.id
    body.abono = nuevoAbono

    if (abonoVigente.id) {
      body.idIngresoVario = abonoVigente.id;
    }
    setLoading(true)
    EntradasService.createEntrada(body).then((data) => {
      setShowModal(false)
      clearForm();
      setTipoVenta("1");
      imprimirEntrada(data)
      setShowModal(false)
    }).finally(() => setLoading(false))

  }

  function clearForm() {
    if (tipoVenta === "1") {
      clearFormEntradas();
    } else if (tipoVenta === "2") {
      clearFormAbonos();
    }
  }

  function clearFormEntradas() {
    setForm(emptyForm())
    setAmount(0)
    setLastDniSearched(0)
    setAbonoVigente({})
    setExento({})
    requestFocus();
    setValidated(false);
  }

  function clearFormAbonos() {
    setNuevoAbono(null);
    setAmount(0);
    setAbonoFormValidated(false);
  }

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setTipoVenta(value);
  }

  function buscarAbonoVigente(respuesta) {
    if (respuesta.abono) {
      setAbonoVigente(respuesta.abono)
    } else {
      setAbonoVigente({})
    }
  }

  function buscarExento(respuesta) {
    if (respuesta.exento) {
      setExento(respuesta.exento)
    } else {
      setExento({})
    }
  }

  function confirmar() {
    if (tipoVenta === "1") {
      if (formRef?.current?.checkValidity()) {
        setShowModal(true)
      }
      setValidated(true)
    } else if (tipoVenta === "2") {
      if (abonoFormRef?.current?.checkValidity()) {
        setShowModal(true)
      }
      setAbonoFormValidated(true);
    }
  }


  function buscarPersonaByDni() {
    if (form.dni &&
      form.dni.length >= 7 &&
      form.dni !== lastDniSearched) {
      setLoading(true)
      PersonaService.getPersonaByDNI(form.dni).then(respuesta => {
        if (respuesta.persona) {
          const newForm = {
            ...form,
            nombre: respuesta.persona.nombre,
            apellido: respuesta.persona.apellido,
            localidad: respuesta.persona.localidad,
            direccion: respuesta.persona.direccion,
            telefono: respuesta.persona.telefono
          }
          setForm(newForm)
          buscarAbonoVigente(respuesta)
          buscarExento(respuesta)
        }
      }).catch(error => {
        if (error.response?.status === 404) {
          //Ignoramos este error
        } else {
          console.error(error)
        }
      }).finally(() => setLoading(false))
      setLastDniSearched(form.dni)
    }
  }

  function checkKeyPress(event) {
    if (event.key === 'Enter') {
      buscarPersonaByDni();
    }
  }

  return (
    <div ref={divRef}>
      <Container>
        <Row>
          <Col md={12}>
            <Form.Group className="central-div" >
              <div className="venta-check">
                <input type="radio" class="form-check-input" value="1" name="tipoVenta"
                  id="radioVentaEntrada"
                  checked={tipoVenta === "1"}
                  onChange={handleRadioChange}
                ></input>
                <label class="form-check-label" htmlFor="radioVentaEntrada" className="venta-check">Vender entradas</label>

                <input type="radio" class="form-check-input" value="2" name="tipoVenta"
                  id="radioVentaAbono"
                  checked={tipoVenta === "2"}
                  onChange={handleRadioChange}
                ></input>
                <label class="form-check-label" htmlFor="radioVentaAbono" className="venta-check">
                  Vender abonos</label>
              </div>
            </Form.Group>
          </Col>
          <Col md={8}>
            <Form noValidate validated={validated}
              className="central-div"
              autoComplete="off"
              style={{ minHeight: '520px' }}
              ref={formRef}
            >
              {tipoVenta === "1" && <Row className="row-mb-5">
                <Col md={4}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Datos del Titular</Card.Title>

                      <Form.Group controlId="validationCustom01">
                        <Form.Label>DNI</Form.Label>
                        <Form.Control type="text" name="dni"
                          disabled={abonoVigente.id || exento.id}
                          required
                          ref={dniInputRef}
                          value={form.dni}
                          onChange={e => handleFormValue('dni', e.target.value)}
                          onKeyDown={checkKeyPress}
                          onBlur={buscarPersonaByDni} />
                      </Form.Group>

                      <Form.Group controlId="NOMBRE">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" name="nombre"
                          disabled={abonoVigente.id || exento.id}
                          required
                          value={form.nombre} onChange={e => handleFormValue('nombre', e.target.value)} />
                      </Form.Group>

                      <Form.Group controlId="APELLIDO">
                        <Form.Label>Apellido</Form.Label>
                        <Form.Control type="text" name="apellido"
                          disabled={abonoVigente.id || exento.id}
                          required
                          value={form.apellido} onChange={e => handleFormValue('apellido', e.target.value)} />
                      </Form.Group>

                      <Form.Group controlId="LOCALIDAD">
                        <Form.Label>Localidad</Form.Label>
                        <LocalidadSelect
                          value={form.localidad} onSelect={e => handleFormValue('localidad', e.target.value)} />
                      </Form.Group>

                      <Form.Group controlId="DIRECCION">
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control type="text" name="direccion"
                          required
                          value={form.direccion} onChange={e => handleFormValue('direccion', e.target.value)} />
                      </Form.Group>

                      <Form.Group controlId="TELEFONO">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control type="text" name="telefono"
                          required
                          value={form.telefono} onChange={e => handleFormValue('telefono', e.target.value)} />
                      </Form.Group>

                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Visitas Locales</Card.Title>
                      <Form.Group controlId="MenoresLocales" validationState={null} >
                        <Form.Label>4 a 11 años</Form.Label>
                        <NumericInput mobile className="form-control" value={form.menoresLocales1} min={0}
                          onChange={value => handleFormValue('menoresLocales1', value)} />
                      </Form.Group>
                      <Form.Group controlId="MenoresLocales">
                        <Form.Label>12 a 18 años</Form.Label>
                        <NumericInput mobile className="form-control" value={form.menoresLocales2} min={0}
                          onChange={value => handleFormValue('menoresLocales2', value)} />
                      </Form.Group>
                      <Form.Group controlId="AdultosLocales">
                        <Form.Label>Adultos</Form.Label>
                        <NumericInput mobile className="form-control" value={form.adultosLocales} min={0}
                          onChange={value => handleFormValue('adultosLocales', value)} />
                      </Form.Group>
                      <Form.Group controlId="JubiladosLocales">
                        <Form.Label>Jubilados</Form.Label>
                        <NumericInput mobile className="form-control" value={form.jubiladosLocales} min={0}
                          onChange={value => handleFormValue('jubiladosLocales', value)} />
                      </Form.Group>
                      <Form.Group controlId="GroupoFamiliarLocales">
                        <Form.Label>Grupo Familiar</Form.Label>
                        <NumericInput mobile className="form-control" value={form.grupoFamiliarLocales} min={0}
                          onChange={value => handleFormValue('grupoFamiliarLocales', value)} />
                      </Form.Group>
                      <Form.Group controlId="CarpasLocales">
                        <Form.Label>Carpas</Form.Label>
                        <NumericInput mobile className="form-control" value={form.carpasLocales} min={0}
                          onChange={value => handleFormValue('carpasLocales', value)} />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Visitas Generales</Card.Title>
                      <Form.Group controlId="MenoresGenerales">
                        <Form.Label>4 a 11 años</Form.Label>
                        <NumericInput mobile className="form-control" value={form.menoresGenerales1} min={0}
                          onChange={value => handleFormValue('menoresGenerales1', value)} />
                      </Form.Group>
                      <Form.Group controlId="MenoresGenerales">
                        <Form.Label>12 a 18 años</Form.Label>
                        <NumericInput mobile className="form-control" value={form.menoresGenerales2} min={0}
                          onChange={value => handleFormValue('menoresGenerales2', value)} />
                      </Form.Group>
                      <Form.Group controlId="AdultosGenerales">
                        <Form.Label>Adultos</Form.Label>
                        <NumericInput mobile className="form-control" value={form.adultosGenerales} min={0}
                          onChange={value => handleFormValue('adultosGenerales', value)} />
                      </Form.Group>
                      <Form.Group controlId="JubiladosGenerales">
                        <Form.Label>Jubilados</Form.Label>
                        <NumericInput mobile className="form-control" value={form.jubiladosGenerales} min={0}
                          onChange={value => handleFormValue('jubiladosGenerales', value)} />
                      </Form.Group>
                      <Form.Group controlId="GroupoFamiliarGenerales">
                        <Form.Label>Grupo Familiar</Form.Label>
                        <NumericInput mobile className="form-control" value={form.grupoFamiliarGenerales} min={0}
                          onChange={value => handleFormValue('grupoFamiliarGenerales', value)} />
                      </Form.Group>
                      <Form.Group controlId="CarpasGenerales">
                        <Form.Label>Carpas</Form.Label>
                        <NumericInput mobile className="form-control" value={form.carpasGenerales} min={0}
                          onChange={value => handleFormValue('carpasGenerales', value)} />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>}
              {tipoVenta === "2" && <Row className="row-mb-5">
                <Col md={12}>
                  <AbonoProvider abono={nuevoAbono} setAbono={setNuevoAbono} formRef={abonoFormRef} formValidated={abonoFormValidated}>
                    <GenerarAbono />
                  </AbonoProvider>
                </Col>
              </Row>
              }
            </Form>
          </Col>
          <Col md={4}>
            <div className="central-div" style={{ minHeight: '520px' }} >
              <div className="center">
                <h1>Total</h1>
                <h1>{formatCurrency(amount)}</h1>
                <ButtonGroup>
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      name="radio"
                      value={radio.value}
                      variant="outline-primary"
                      checked={form.medioPago === radio.value}
                      onChange={(e) => handleFormValue('medioPago', e.currentTarget.value)}
                      disabled={!radio.clickeable}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
                {abonoVigente.id && <div className="abono-div">
                  <h4>Datos del abono:</h4>
                  <b>Precio:</b>{formatCurrency(abonoVigente.precio)}<br />
                  <b>Fecha de pago: </b>{UtilsService.formatDate(abonoVigente.fechaPago)}<br />
                  <b>Fecha vencimiento: </b>{UtilsService.formatDate(abonoVigente.fechaVencimiento)}<br />
                  <b>Pagado por: </b>{abonoVigente.pagadoPor}<br />
                  <b>Generado en: </b>{abonoVigente.generadoEn}<br />
                  {abonoVigente.generadoEn === 'PALACIO_MUNICIPAL' ? <><b>Número ingreso vario: </b>{abonoVigente.numeroIngresoVario} <br /></> : <></>}
                </div>}

                {exento.id && <div className="abono-div">
                  <h4>Datos del exento:</h4>
                  DNI: {exento.dni}<br />
                  Nombre: {exento.nombre}<br />
                  Apellido: {exento.apellido}<br />
                  Exención generada por: {exento.usuario}<br />
                  Motivo de la exención: {exento.motivo}<br />
                  Fecha desde: {exento.fechaDesde}<br />
                  Fecha hasta: {exento.fechaHasta}
                </div>}

              </div>

              <div className="center-detalles">
                {amount > 0 ? (
                  <h3>Detalles:</h3>
                ) : <></>}
                <ul style={{ fontSize: '14px', overflow: 'auto', }}>
                  {form.menoresLocales1 ? (<li className="detalle-entrada-li">
                    <span>{`* ${form.menoresLocales1} De 4 a 11 años (Locales):`}</span>
                    <span>{formatCurrency(precios.menoresLocales1 * form.menoresLocales1)} </span></li>) : <></>}
                  {form.menoresLocales2 ? (<li className="detalle-entrada-li">
                    <span>{`* ${form.menoresLocales2} De 12 a 18 años (Locales):`}</span>
                    <span>{formatCurrency(precios.menoresLocales2 * form.menoresLocales2)}</span></li>) : <></>}
                  {form.adultosLocales ? <li className="detalle-entrada-li">
                    <span>{`* ${form.adultosLocales} De Adultos (Locales):`}</span>
                    <span>{formatCurrency(precios.adultosLocales * form.adultosLocales)}</span></li> : <></>}
                  {form.jubiladosLocales ? <li className="detalle-entrada-li">
                    <span>{`* ${form.jubiladosLocales} De Jubilados (Locales):`}</span>
                    <span>{formatCurrency(precios.jubiladosLocales * form.jubiladosLocales)}</span></li> : <></>}
                  {form.grupoFamiliarLocales ? <li className="detalle-entrada-li">
                    <span>{`* ${form.grupoFamiliarLocales} De Grupo Familiar (Locales):`}</span>
                    <span>{formatCurrency(precios.grupoFamiliarLocales * form.grupoFamiliarLocales)}</span> </li> : <></>}
                  {form.carpasLocales ? <li className="detalle-entrada-li">
                    <span>{`* ${form.carpasLocales} De Carpas (Locales): `}</span>
                    <span>{formatCurrency(precios.carpasLocales * form.carpasLocales)}</span> </li> : <></>}
                  {form.menoresGenerales1 ? <li className="detalle-entrada-li">
                    <span>{`* ${form.menoresGenerales1}  De 4 a 11 años (Generales):`}</span>
                    <span>{formatCurrency(precios.menoresGenerales1 * form.menoresGenerales1)}</span> </li> : <></>}
                  {form.menoresGenerales2 ? <li className="detalle-entrada-li">
                    <span>{`* ${form.menoresGenerales2}   De 12 a 18 años (Generales):`}</span>
                    <span>{formatCurrency(precios.menoresGenerales2 * form.menoresGenerales2)}</span></li> : <></>}
                  {form.adultosGenerales ? <li className="detalle-entrada-li">
                    <span>{`* ${form.adultosGenerales}  De Adultos (Generales):`}</span>
                    <span>{formatCurrency(precios.adultosGenerales * form.adultosGenerales)}</span></li> : <></>}
                  {form.jubiladosGenerales ? <li className="detalle-entrada-li">
                    <span>{`* ${form.jubiladosGenerales}  De Jubilados (Generales):`}</span>
                    <span>{formatCurrency(precios.jubiladosGenerales * form.jubiladosGenerales)}</span></li> : <></>}
                  {form.grupoFamiliarGenerales ? <li className="detalle-entrada-li">
                    <span>{`* ${form.grupoFamiliarGenerales}  De Grupo Familiar (Generales):`}</span>
                    <span>{formatCurrency(precios.grupoFamiliarGenerales * form.grupoFamiliarGenerales)}</span></li> : <></>}
                  {form.carpasGenerales ? <li className="detalle-entrada-li">
                    <span>{`* ${form.carpasGenerales}  De Carpas (Generales):`}</span>
                    <span>{formatCurrency(precios.carpasGenerales * form.carpasGenerales)}</span></li> : <></>}
                  {nuevoAbono ? (() => {
                    const tipoAbonoOption = tiposAbono.find(option => option.value === nuevoAbono.tipoAbono);

                    if (tipoAbonoOption && tipoAbonoOption.value) {
                      const costoAbono = precios[tipoAbonoOption.precioAbono] * nuevoAbono.duracionMeses;
                      return <li className="detalle-entrada-li">
                        <span>{`* Abono ${tipoAbonoOption.label} x ${nuevoAbono.duracionMeses} mes(es):`}</span>
                        <span>{formatCurrency(costoAbono)}</span>
                      </li>
                    }
                    return null;
                  })() : <></>}
                </ul>
              </div>
            </div>

          </Col>
        </Row>
        <Row className="row-mb-5">
          <Col>
            <div className="central-div">
              <Button size="lg" variant="danger" className="button-mr-5" onClick={clearForm}>
                Limpiar
              </Button>
              <Button size="lg"
                type="submit"
                onClick={() => confirmar()}
                disabled={form.medioPago !== 'ABONO' && amount <= 0 && form.medioPago !== 'EXENTO'}
              >
                Confirmar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} animation={false} /*este modal es de confirmacion de toda la pagina de venta de entradas*/>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }} ><h4>¿Generar entrada?</h4></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={submit}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>


    </div>

  )
}

export default Entradas
