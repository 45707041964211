import { AutoNumericInput } from "react-autonumeric";
import AutoNumeric from "autonumeric";

const autoNumericOptions = {
  currencySymbol: "$ ",
  decimalCharacter: ",",
  decimalCharacterAlternative: ".",
  decimalPlacesRawValue: 2,
  allowDecimalPadding: 2,
  decimalPlaces: 2,
  digitGroupSeparator: ".",
  modifyValueOnWheel: false,
  modifyValueOnUpDownArrow: false,
}

export const CurrencyInput = function (props) {
  return <AutoNumericInput
    inputProps={{ className: "form-control" }}
    autoNumericOptions={autoNumericOptions}
    {...props}
  />
}

export const formatCurrency = (value) => {
  if (Number.isNaN(value) || value === "NaN") {
    return "NaN";
  }
  return AutoNumeric.format(value, autoNumericOptions);
}

export const unformatCurrency = (value) => {
  return Number(AutoNumeric.unformat(value, autoNumericOptions));
}