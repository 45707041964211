import React from 'react';
import { Container } from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import EstadisticaService from '../../../services/estadisticas-service';
import DatePicker from '../../shared/date-picker';
import ToggleButton from 'react-bootstrap/ToggleButton'

import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { NoData } from './noDataPlugin';
import { Doughnut, Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const COLORS = ['rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)'];

const LABELS = [
  'Menores 4 a 11',
  'Menores 12 a 18',
  'Adultos',
  'Jubilados',
  'Grupo Familiar',
  'Carpa',
]

const porcentajesChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'bottom'
    },
    title: {
      display: true,
      text: 'Porcentajes de visitantes por categoria',
    },
    layout: {
      padding: 20
    }
  }
}

const cantidadesChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Cantidades de visitantes por categoria',
    },
    layout: {
      padding: 20
    }
  }
}

const porcentajesMediosPagoChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'bottom'
    },
    title: {
      display: true,
      text: 'Porcentajes de tickets por medio de pago',
    },
    layout: {
      padding: 20
    }
  }
}

const cantidadesMediosPagoChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Cantidades de tickets por medio de pago',
    },
    layout: {
      padding: 20
    }
  }
}

function Estadisticas() {

  const [rawData, setRawData] = React.useState({})
  const [porcentajesChartData, setPorcentajesChartData] = React.useState(null)
  const [cantidadesChartData, setCantidadesChartData] = React.useState(null)
  const [porcentajesMediosPagoChartData, setPorcentajesMediosPagoChartData] = React.useState(null)
  const [cantidadesMediosPagoChartData, setCantidadesMediosPagoChartData] = React.useState(null)
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [locales, setLocales] = React.useState(true);
  const [visitantes, setVisitantes] = React.useState(true);

  React.useEffect(() => {
    if (rawData.cantidades) {
      generateCantidadesChartData(rawData)
    }
  }, [locales, visitantes])

  React.useEffect(() => {
    EstadisticaService.getEstadisticasCantidad(startDate, endDate).then(data => {
      setRawData(data)
      generateCantidadesChartData(data)
      generateMediosPagoChartData(data)
    })
  }, [startDate, endDate])

  function generateMediosPagoChartData(data) {
    const totalCantidad = data.mediosPago.reduce((acum, actual) => acum + actual.cantidad, 0);
    const labels = data.mediosPago.map(medioPago => medioPago.medioPago)

    const locPorcentajesMediosPagoDataChartData = {
      labels,
      datasets: [
        {
          label: 'Medios de pago',
          data: data.mediosPago.map(medioPago => +(medioPago.cantidad * 100 / totalCantidad).toFixed(2)),
          backgroundColor: COLORS,
          borderColor: COLORS,
          borderWidth: 1,
        },
      ],
    };

    const locCantidadesMedioPagoChartData = {
      labels,
      datasets: [
        {
          data: data.mediosPago.map(medioPago => medioPago.cantidad),
          backgroundColor: COLORS
        }
      ]
    }

    setPorcentajesMediosPagoChartData(locPorcentajesMediosPagoDataChartData);
    setCantidadesMediosPagoChartData(locCantidadesMedioPagoChartData);
  }

  function generateCantidadesChartData(data) {
    const { cantidades } = data
    const resultados = {
      menores1: 0,
      menores2: 0,
      adultos: 0,
      jubilados: 0,
      grupoFamiliar: 0,
      carpas: 0
    }

    if (locales) {
      resultados.menores1 += cantidades.menoresLocales1
      resultados.menores2 += cantidades.menoresLocales2
      resultados.adultos += cantidades.adultosLocales
      resultados.jubilados += cantidades.jubiladosLocales
      resultados.grupoFamiliar += cantidades.grupoFamiliarLocales
      resultados.carpas += cantidades.carpasLocales
    }
    if (visitantes) {
      resultados.menores1 += cantidades.menoresGenerales1
      resultados.menores2 += cantidades.menoresGenerales2
      resultados.adultos += cantidades.adultosGenerales
      resultados.jubilados += cantidades.jubiladosGenerales
      resultados.grupoFamiliar += cantidades.grupoFamiliarGenerales
      resultados.carpas += cantidades.carpasGenerales
    }

    const totalResultados =
      resultados.menores1
      + resultados.menores2
      + resultados.adultos
      + resultados.jubilados
      + resultados.grupoFamiliar
      + resultados.carpas

    resultados.porcMenores1 = resultados.menores1 * 100 / totalResultados
    resultados.porcMenores2 = resultados.menores2 * 100 / totalResultados
    resultados.porcAdultoss = resultados.adultos * 100 / totalResultados
    resultados.porcJubilados = resultados.jubilados * 100 / totalResultados
    resultados.porcGrupoFamiliar = resultados.grupoFamiliar * 100 / totalResultados
    resultados.porcCarpas = resultados.carpas * 100 / totalResultados

    const locPorcentajesChartData = {
      labels: LABELS,
      datasets: [
        {
          label: 'Porcentajes',
          data: [
            +resultados.porcMenores1.toFixed(2),
            +resultados.porcMenores2.toFixed(2),
            +resultados.porcAdultoss.toFixed(2),
            +resultados.porcJubilados.toFixed(2),
            +resultados.porcGrupoFamiliar.toFixed(2),
            +resultados.porcCarpas.toFixed(2)
          ],
          backgroundColor: COLORS,
          borderColor: COLORS,
          borderWidth: 1,
        },
      ],
    };

    const locCantidadesChartData = {
      labels: LABELS,
      datasets: [
        {
          data: [
            resultados.menores1,
            resultados.menores2,
            resultados.adultos,
            resultados.jubilados,
            resultados.grupoFamiliar,
            resultados.carpas
          ],
          backgroundColor: COLORS
        }
      ]
    }

    setPorcentajesChartData(locPorcentajesChartData)
    setCantidadesChartData(locCantidadesChartData)
  }

  return (
    <div>
      <Container className="central-div">
        <Card style={{ minHeight: '615px' }}>
          <h1>Estadísticas</h1>
          <Row>
            <Col md={4}>
              <h3>Fecha desde</h3>
              <DatePicker selected={startDate}
                onChangeRaw={e => e.preventDefault()}
                onChange={date => setStartDate(date)}
              />
            </Col>
            <Col md={4}>
              <h3>Fecha hasta</h3>
              <DatePicker selected={endDate}
                onChangeRaw={e => e.preventDefault()}
                onChange={date => setEndDate(date)}
              />
            </Col>
            <Col md={4}>
              <ToggleButton
                id="toggle-check"
                type="checkbox"
                variant="info"
                checked={locales}
                value="1"
                onChange={(e) => setLocales(e.currentTarget.checked)}
              >
                Locales
              </ToggleButton>
              <ToggleButton
                id="toggle-check"
                type="checkbox"
                variant="info"
                checked={visitantes}
                value="1"
                onChange={(e) => setVisitantes(e.currentTarget.checked)}
              >
                Visitantes
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='chart-div'>
                {cantidadesChartData && <Bar
                  plugins={[NoData]}
                  options={cantidadesChartOptions}
                  data={cantidadesChartData} />
                }
              </div>
            </Col>
            <Col>
              <div className='chart-div'>
                {porcentajesChartData && <Doughnut
                  plugins={[NoData]}
                  options={porcentajesChartOptions}
                  data={porcentajesChartData} />
                }
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='chart-div'>
                {cantidadesMediosPagoChartData && <Bar
                  plugins={[NoData]}
                  options={cantidadesMediosPagoChartOptions}
                  data={cantidadesMediosPagoChartData} />}
              </div>
            </Col>
            <Col>
              <div className='chart-div'>
                {porcentajesMediosPagoChartData && <Doughnut
                  plugins={[NoData]}
                  options={porcentajesMediosPagoChartOptions}
                  data={porcentajesMediosPagoChartData} />}
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </div >
  );
}
export default Estadisticas
