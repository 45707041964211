import axios from 'axios';


function fromServer(abono) {

  if (abono.fechaDesde) {
    abono.fechaDesde = new Date(abono.fechaDesde);
  }
  if (abono.fechaHasta) {
    abono.fechaHasta = new Date(abono.fechaHasta);
  }
  return abono
}

const AbonoService = {
  getAbonoVigente: () => {
    return axios.get('/api/polideportivo/abono/vigente').then(resp => resp.data)
  },
  getAbonos: () => {
    return axios.get('/api/polideportivo/abono').then(resp => resp.data.map(fromServer))
  },
  createAbono: (body) => {
    return axios.post('/api/polideportivo/abono', body).then(resp => resp.data)
      .catch(error => {
        console.error('Error al crear el abono:', error.response?.data || error.message);
        throw error;
      });
  },
  getAbono: id => {
    return axios.get('/api/polideportivo/abono/' + id).then(resp => fromServer(resp.data))
  },
  updateAbono: (abono) => {
    return axios.put('/api/polideportivo/abono/', abono).then(resp => resp.data)
      .catch(error => {
        console.error('Error al modificar el abono:', error.response?.data || error.message);
        throw error;
      });
  }

}

export default AbonoService

