import React from 'react'

import PreciosService from '../../../../services/precios-service'

import '../../../../App.css'

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { UserContext } from '../../../../contexts/userContext'

import { Link } from "react-router-dom";
import UtilsService from '../../../../services/utils-service';
import { formatCurrency } from "../../../shared/currency-input";


function PreciosList() {

  const [precios, setPrecios] = React.useState([])
  const { user } = React.useContext(UserContext)


  React.useEffect(() => {
    PreciosService.getPrecios().then(data => { setPrecios(data) })
  }, [])

  return (

    <Container className="central-div">
      <Row>
        <Col>
          <h1>Precios</h1>
          <Link to={"/precios/new"} style={{ float: 'right' }}>
            <Button >
              + Agregar precio
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className='tabla-preciosList'>
            <Table striped bordered hover size="sm" >
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Adlts grales</th>
                  <th>Adlts locales</th>
                  <th>Carpas grales</th>
                  <th>Carpas locales</th>
                  <th>Grupo familiar grales</th>
                  <th>Grupo familiar local</th>
                  <th>Jubilado grales</th>
                  <th>Jubilados locales</th>
                  <th>Menores grales1</th>
                  <th>Menores grales2</th>
                  <th>Menores locales1</th>
                  <th>Menores locales2</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {precios.map((precio, index) =>
                  <tr key={index}>
                    <td>{UtilsService.formatDate(precio.fechaFin)}</td>
                    <td>{formatCurrency(precio.adultosGenerales)}</td>
                    <td>{formatCurrency(precio.adultosLocales)}</td>
                    <td>{formatCurrency(precio.carpasGenerales)}</td>
                    <td>{formatCurrency(precio.carpasLocales)}</td>
                    <td>{formatCurrency(precio.grupoFamiliarGenerales)}</td>
                    <td>{formatCurrency(precio.grupoFamiliarLocales)}</td>
                    <td>{formatCurrency(precio.jubiladosGenerales)}</td>
                    <td>{formatCurrency(precio.jubiladosLocales)}</td>
                    <td>{formatCurrency(precio.menoresGenerales1)}</td>
                    <td>{formatCurrency(precio.menoresGenerales2)}</td>
                    <td>{formatCurrency(precio.menoresLocales1)}</td>
                    <td>{formatCurrency(precio.menoresLocales2)}</td>
                    <Link to={"/precios/" + precio.id}>
                      <td>
                        <Button>Modificar</Button>
                      </td>
                    </Link>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>





  )

}

export default PreciosList