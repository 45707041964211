import axios from 'axios';
import UtilsService from './utils-service'

function fromServer(cierre) {
  if (cierre.fecha) {
    cierre.fecha = new Date(cierre.fecha)
  }
  return cierre
}

const CierreCajaService = {
  getCierreCaja: (startDate, endDate) => {
    return axios.get('/api/polideportivo/cierre-caja',
      {
        params: {
          startDate: UtilsService.formatDateToServer(startDate),
          endDate: UtilsService.formatDateToServer(endDate),
        }
      }
    ).then(resp => resp.data.map(fromServer))
  },
  createCierreCaja: cierreCaja => {
    return axios.post('/api/polideportivo/cierre-caja', cierreCaja).then(resp => resp.data)
  },
  getReporteCierre: (id) => {
    return UtilsService.getUrlDataFromPdfEndpoint(`/api/polideportivo/cierre-caja/${id}/reporte`)
  }
  
}

export default CierreCajaService