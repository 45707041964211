import axios from 'axios';

function fromServer(exento) {

    if (exento.fechaDesde) {
      exento.fechaDesde = new Date(exento.fechaDesde);
    }
    if (exento.fechaHasta) {
      exento.fechaHasta = new Date(exento.fechaHasta);
    }
    return exento
  }

const ExentoService = {
    getExentos: () => {
        return axios.get('/api/polideportivo/exento').then(resp => resp.data.map(fromServer))
    },
    createExento: exento => {
        return axios.post('/api/polideportivo/exento', exento).then(resp => resp.data)
    },
    getExento: id => {
        return axios.get('/api/polideportivo/exento/' + id).then(resp => fromServer(resp.data))
    },
    updateExento: exento => {
        return axios.put('/api/polideportivo/exento', exento).then(resp => resp.data)
    },
    anularExento: id => {
        return axios.post(`/api/polideportivo/exento/${id}/anular`).then(resp => resp.data)
    }

}

export default ExentoService