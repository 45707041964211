import React from "react";
import PersonaService from "../../services/persona-service";
import { UIContext } from '../../contexts/uiContext'
import { Form } from "react-bootstrap";

function LocalidadSelect(props) {

  const [localidades, setLocalidades] = React.useState([])
  const { showMessage } = React.useContext(UIContext)

  React.useEffect(() => {
    PersonaService.getLocalidades()
      .then(data => setLocalidades(data))
      .catch(() => showMessage('Ocurrió u error al recuperar las localidades', 'error'))

  }, [])

  return (
    <Form.Select onChange={props.onSelect} name={props.name} value={props.value}>
      <option>Seleccionar localidad</option>
      {localidades.map(localidad => <option key={localidad}>{localidad}</option>)}
    </Form.Select>
  )
}

export default LocalidadSelect