import axios from 'axios';
import UtilsService from './utils-service'

function fromServer(entrada) {
  if (entrada.fecha) {
    entrada.fecha = new Date(entrada.fecha)
  }
  return entrada
}

const EntradasService = {
  getEntradas: (usuario, startDate, endDate) => {
    
    const params = {
      startDate: UtilsService.formatDateToServer(startDate),
      endDate: UtilsService.formatDateToServer(endDate)
    }
    if(usuario) {
      params.usuario = usuario
    }

    return axios.get('/api/polideportivo/entradas',
      {
        params: params      
      }
    ).then(resp => resp.data.map(fromServer))
  },
  createEntrada: entrada => {
    return axios.post('/api/polideportivo/entradas', entrada).then(resp => resp.data)
  },

  anularEntrada: id => {
    return axios.post(`/api/polideportivo/entradas/${id}/anular`).then(resp => resp.data)
  },

  getTotalCierreCaja: (startDate, endDate) => {
    return axios.get('/api/polideportivo/entradas/total-cierre-caja',
      {
        params: {
          startDate: UtilsService.formatDateToServer(startDate),
          endDate: UtilsService.formatDateToServer(endDate)
        }
      }
    ).then(resp => resp.data)
  },

  getReporteEntrada: (id) => {
    return UtilsService.getUrlDataFromPdfEndpoint(`/api/polideportivo/entradas/${id}/reporte`)
  }
}

export default EntradasService