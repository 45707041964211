import React from 'react'

import AbonoService from '../../../../services/abono-service'

import '../../../../App.css'

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import UtilsService from '../../../../services/utils-service';
import { Link } from "react-router-dom";
import tiposAbono from '../tipos-abono';

function AbonoList() {
  const [abonos, setAbonos] = React.useState([])

  React.useEffect(() => {
    AbonoService.getAbonos().then(data => { setAbonos(data) })
  }, [])

  return (
    <Container className="central-div">
      <Row>
        <Col>
          <h1>Abonos</h1>
        </Col>
      </Row>
      <Row>
        <Col md={15}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>DNI</th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Tipo de abono</th>
                <th>Abono vigente desde</th>
                <th>Abono vigente hasta</th>
                <th>Estado del abono</th>
                <th>Generado por</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {abonos.map((abono, index) => {
                const personaEncargada = abono.personas.find(persona => persona.encargado);
                return (

                  <tr key={index}>
                    <td>{personaEncargada?.dni}</td>
                    <td>{personaEncargada?.nombre}</td>
                    <td>{personaEncargada?.apellido}</td>
                    <td>{tiposAbono.find(tipoAbono => tipoAbono.value === abono.tipoAbono).label}</td>
                    <td>{UtilsService.formatDate(abono.fechaDesde)}</td>
                    <td>{UtilsService.formatDate(abono.fechaHasta)}</td>
                    <td>{(abono.fechaHasta < Date.now() && abono.estado === 'ACTIVO') ? 'VENCIDO' : abono.estado}</td>
                    <td>{abono.usuario}</td>
                    <Link to={`/abono/${abono.id}`} >
                      <td>
                        <Button >Modificar</Button>
                      </td>
                    </Link>
                  </tr>
                );
              }
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )

}
export default AbonoList