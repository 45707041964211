import axios from 'axios';

function fromServer(data) {
  if (data?.abono?.fechaPago) {
    data.abono.fechaPago = new Date(data.abono.fechaPago);
  }
  if (data?.abono?.fechaVencimiento) {
    data.abono.fechaVencimiento = new Date(data.abono.fechaVencimiento);
  }
  return data;
}

const PersonaService = {
  getPersonaByDNI: dni => {
    return axios.get('/api/polideportivo/persona/' + dni).then(resp => fromServer(resp.data))
  },
  getLocalidades: () => {
    return axios.get('/api/polideportivo/localidades').then(resp => resp.data)
  }


}
export default PersonaService