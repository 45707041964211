import React from "react";
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import Form from 'react-bootstrap/Form';

import { es } from "date-fns/locale";

registerLocale("es", es)

function DatePicker(props) {
  return <ReactDatePicker
    className="date-picker-input"
    dropdownMode="select"
    customInput={<Form.Control></Form.Control>}
    dateFormat='dd/MM/yyyy'
    locale="es"
    {...props}
  />

}

export default DatePicker;
