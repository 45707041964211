import Authentication from './authentication-service';

function addLeadingZero(value) {
  if (value.toString().length === 1) {
    return '0' + value;
  }
  return value.toString();
}

const UtilsService = {
  formatDateToServer: date => {
    const hour = addLeadingZero(date.getHours().toString())
    const minutes = addLeadingZero(date.getMinutes().toString())
    const day = addLeadingZero(date.getDate().toString())
    const month = addLeadingZero((date.getMonth() + 1).toString())
    return `${date.getFullYear()}-${month}-${day}T${hour}:${minutes}`
  },

  formatDate: date => {
    return addLeadingZero(date.getDate()) + "/" + addLeadingZero(date.getMonth() + 1) + "/" + date.getFullYear();
  },

  formatDateTime: date => {
    return addLeadingZero(date.getDate()) + 
    "/" + 
    addLeadingZero(date.getMonth() + 1) + 
    "/" + 
    date.getFullYear() + 
    " " + 
    addLeadingZero(date.getHours()) +
    ":" +
    addLeadingZero(date.getMinutes())
  },

  getUrlDataFromPdfEndpoint: (url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.setRequestHeader('Authorization', 'Bearer ' + Authentication.getAuthToken());

      xhr.onreadystatechange = (e) => {
        if (e.target.readyState === 4) {
          if (e.target.status === 200) {
            const dataUrl = URL.createObjectURL(e.target.response);
            resolve(dataUrl);
            setTimeout(() => {
              URL.revokeObjectURL(dataUrl);
            }, 10000);
          } else {
            reject('Error getting the PDF');
          }
        }
      }

      xhr.send();
    })
  }
}

export default UtilsService