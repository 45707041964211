import React from 'react'

import CierreCajaService from '../../../../services/cierre-caja-service'
import { UIContext } from '../../../../contexts/uiContext'

import '../../../../App.css'

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";

import DatePicker from '../../../shared/date-picker';
import { formatCurrency } from "../../../shared/currency-input";
import { BsFillPrinterFill } from "react-icons/bs";
import UtilsService from '../../../../services/utils-service';

function CierresList() {

  const [cierres, setCierres] = React.useState([])

  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());

  const { showMessage, printDataUrl, setLoading } = React.useContext(UIContext)

  React.useEffect(() => {
    setLoading(true)
    CierreCajaService.getCierreCaja(startDate, endDate)
      .then(data => { setCierres(data) })
      .finally(() => setLoading(false))
  }, [startDate, endDate])

  const reimprimirCierre = id => {
    setLoading(true)
    CierreCajaService.getReporteCierre(id).then(dataUrl => {
      printDataUrl(dataUrl);
    }).catch(error => {
      showMessage(error, 'error');
    }).finally(() => setLoading(false))
  }

  return (
    <Container className="central-div">
      <Row>
        <Col>
          <h1>
            Cierre de caja
          </h1>
          <Link to={"/cierres/new"} style={{ float: 'right' }}>
            <Button >
              Cerrar caja ahora
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <DatePicker selected={startDate}
            onChangeRaw={e => e.preventDefault()}
            onChange={date => setStartDate(date)}
          />
        </Col>
        <Col md={6}>
          <DatePicker selected={endDate}
            onChangeRaw={e => e.preventDefault()}
            onChange={date => setEndDate(date)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Usuario</th>
                <th>Fecha</th>
                <th>Monto inicial</th>
                <th>Total</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {cierres.map((cadaCierre, index) =>
                <tr key={index}>
                  <td>{cadaCierre.id}</td>
                  <td>{cadaCierre.usuario}</td>
                  <td>{UtilsService.formatDate(cadaCierre.fecha)}</td>
                  <td>{formatCurrency(cadaCierre.montoInicial)}</td>
                  <td>{formatCurrency(cadaCierre.montoTotal)}</td>
                  <td>
                    <span style={{ cursor: 'pointer' }}>
                      <BsFillPrinterFill onClick={() => {
                        reimprimirCierre(cadaCierre.id)
                      }} />
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>





  )

}

export default CierresList